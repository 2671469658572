import React from "react"

import leftImage from '../../images/jarvis_left_image_background.svg'
import middleImage from '../../images/Jarvisv2_middleImage.png'
import rightImage from '../../images/jarvis_right_image_background.svg'

import video from '../../images/jarvisV2_Demo.gif'

import './jarvis-main-style.css'


const JarvisMain = () => (

    <div>
        <div className='main-container-jarvis'>


            <div className='container-item-left-image'>
                <img src={leftImage} alt="JarvisBackgroundLeft" />
            </div>

            <div className="container-item-middle-left-card">
                <div className="title">Jarvis</div>
                <p>Jarvis is our solution for quick & powerful shift management, empowering your employees, digitising your internal communication and connecting your internal systems to a world-class conversational interface. </p>
                <p><b>Who are we?</b></p>
                <p> Chatforge is a group of people who not only have years of experience in designing and building chatbot solutions but we are deeply passionate about the power of using conversational interfaces in the right way.</p>

                <a href="">
                    <button className="button-colour-jarvis-main longroundbutton-jarvis-main" > Talk to us about Jarvis </button>
                </a>

            </div>
            <div >
                <img src={middleImage} alt="JarvisBackgroundMiddleRight" />

                <div className='animated-gif'>
                    <img src={video} alt="Jarvis Video" height="480" width="220" />
                </div>

            </div>



            <div className='container-item-right-image'>
                <img src={rightImage} alt="JarvisBackgroundRight" />
            </div>


        </div>

    </div >
)


export default JarvisMain