import React from "react"
import peopleImage from "../../images/jarvis_blue_people.png"

import './jarvis-shift-managment-style.css'



const JarvisShiftManagment = () =>
    (
        <div >

            <div className="main-container-shift-managment">

                <div className='container-textboxes-shift-managment'>

                    <div className="top-text-area">
                    Swap shift tomorrow
                    </div>

                    <div className="middle-text-area">
                    Bob Kendrick will work for our shift tomorrow from 10:00 - 18:00 if you can work his shift from 18:00 -23:00. Are you happy to accept this shift swap?
                    </div>

                    <div className="bottom-text-area">
                    Yes
                    </div>

                </div>

                <div className="card-container-shift-managment">

                    <div className="title-shift-managment">Quick and powerfull Shift managment  <img src={peopleImage} alt="Alpha" /> </div>


                    <div className="card-description-shift-managment">
                        <p> With the constantly evolving needs of your business and your employees, your shift management solution needs to match that flexibility. A pinned paper rota in the break room or a spreadsheet emailed around at the start of month doesn’t give you what a modern business needs.</p>
                        <p>Jarvis allows you to dynamically view and manage your shifts including shift swaps, updating availability and get push updates when the plan changes. Optionally, we can provide a rostering optimisation add-on to reduce admin & improve employee satisfaction. </p>

                        <a href="">
                            <button className="button-colour-shift-managment longroundbutton-shift-managment" > Talk to us about Shift Managment </button>
                        </a>

                    </div>

                </div>


            </div>

        </div>
    )

export default JarvisShiftManagment