import React from "react"

import peopleImage from "../../images/jarvis_purple_people.png"

import './jarvis-digitising-internal-com-style.css'



const DigitisingInternalCom = () =>
    (
        <div >

            <div className="main-container-internalcom">


                <div className='container-textboxes-internalcom'>

                    <div className="top-text-area-internalcom">
                    This bot can send notifications to your phone whenever it receives messages. Is this okay?
                    </div>

                    <div className="middle-text-area-internalcom">
                    yes it is
                    </div>

                    <div className="bottom-text-area-internalcom">
                    Okay, I've enabled notifications for you.
                    </div>

                </div>

                <div className="card-container-internalcom">

                    <div className="title-internalcom">Digitising your internal communication <img src={peopleImage} alt="Alpha" /></div>


                    <div className="card-description-internalcom">
                        <p>An email to an “all mail users” mailbox is not enough in the age of digital communication. What if you need to get and organise a response from every employee? Or even worse, just a department or a team? A quick survey? A legal requirement? A request for support?  </p>
                        <p> Jarvis allows you to push out pulse surveys, links and posts to your employees through a simple push notification to their phones. </p>

                        <a href="">
                            <button className=" button-colour-internalcom longroundbutton-internalcom" > Talk to us about Communication </button>
                        </a>

                    </div>

                </div>


            </div>

        </div>
    )

export default DigitisingInternalCom