import React from "react"
import peopleImage from "../../images/jarvis_brownPeople.png"

import './jarvis-connecting-internal-systems-style.css'



const ConnectingInternalSystems = () =>
    (
        <div >

            <div className="main-container-internalsys">

                <div className="card-container-internalsys">

                    <div className="title-internalsys">Connecting your internal systems <img src={peopleImage} alt="Alpha" /></div>


                    <div className="card-description-internalsys">
                        <p>From rostering, shift management, email, calendar, CRM, ERP, process management and any other internet-facing system that your employees rely on, we can integrate with your systems and create one central world-class conversational interface to talk to all of them at once.  </p>
                        <p>No disparate bookmarks, portals, SSO or VPNs, just one digital assistant for all of them. </p>

                        <a href="">
                            <button className="button-colour-internalsys longroundbutton-internalsys" > Talk to us about Integration </button>
                        </a>

                    </div>

                </div>


                <div className='container-textboxes-internalsys'>

                    <div className="top-text-area-internalsys">
                        Please provide the necessary details in order to log your support ticket.
                     </div>

                    <div className="middle-text-area-internalsys">
                        My email is mark@gmail.com. An external hard disk is not responding.
                    </div>

                    <div className="bottom-text-area-internalsys">
                        Thank you, your support ticket has been submitted.
                    </div>

                </div>

            </div>

        </div>
    )

export default ConnectingInternalSystems