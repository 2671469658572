import React from "react"

import Header from '../components/header'
import Footer from '../components/footer'

import JarvisMain from '../components/jarvis-main/jarvis-main-component'
import ShiftManagment from '../components/jarvis-shift-managment/jarvis-shift-managment-component'
import EmpoweringEmployees from '../components/jarvis-empowering-employees/jarvis-empowering-employees-components'
import DigitisingInternalCom from '../components/jarvis-digitising-internal-com/jarvis-digitising-internal-com.component'
import ConnectingInternalSystems from '../components/jarvis-connecting-internal-systems/jarvis-connecting-internal-systems.component'


const Jarvis = () =>
    (
        <div>
            <Header />

            <JarvisMain />

            <ShiftManagment />

            <EmpoweringEmployees />

            <DigitisingInternalCom />

            <ConnectingInternalSystems />
            
            <Footer jarvisFooter="-Jarvis" />
        </div>

    )

export default Jarvis