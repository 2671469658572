import React from "react"
import peopleImage from "../../images/jarvis_green_people.png"
import deskImage from "../../images/jarvis_desk_image.svg"

import './jarvis-empowering-employees-style.css'



const EmpoweringEmployees = () =>
    (
        <div>

            <div className='main-container-empowering'>


                <div className="card-container-empowering">

                    <div className="title-empowering">Empowering your employees   <img src={peopleImage} alt="Alpha" />
                    </div>

                    <div className="card-description-empowering">
                        <p>Based on organisational psychology, Jarvis targets one simple touch point; the start of the work day. This is designed to ensure that your employees know everything they need to get on with their job for the day without unnecessary notifications, nudges or interruptions.</p>
                        <p> This is customisable by your organisation from meetings scheduled for the day, deliveries needed to be made, tasks to complete, people to manage or machines to fix. </p>

                        <a href="">
                            <button className="button-colour-empowering longroundbutton-empowering" > Talk to us about Briefing </button>
                        </a>

                    </div>

                </div>


                <div className='container-textboxes-empowering arrow-image-empowering'>


                    <div className="top-text-area-empowering">
                    What do I need to do tommorow?
                    </div>

                    <div className="middle-text-area-empowering">
                    What checklist would you like to see? Opening or Closing?
                    </div>

                    <div className="bottom-text-area-empowering">
                    Opening
                    </div>

                    <div className="deskImage"><img src={deskImage}  alt="Alpha" />
                    </div>
                    
                </div>



            </div>

        </div>
    )

export default EmpoweringEmployees